import { TourneyContainer } from "./styles/TournamentDisplay.styled";
import LiveBadge from "../LiveBadge";
import Image from "next/image";
import { useTournamentQuery } from "../../hooks/Queries/useGame";
import { ErrorText } from "../../styles/globalStyles";
import { useModal } from "../../hooks/useModal";
import Schedule from "./Schedule";
import CalendarIcon from "../../assets/icons/Calendar";

const getMonthString = (date: Date): string => {
  return date.toLocaleString("default", { month: "short" });
};

interface Props {
  showSchedule?: boolean;
  overrideModal?: () => void;
}

const TournamentDisplay = ({ showSchedule = true, overrideModal }: Props) => {
  const { data, isLoading, isError } = useTournamentQuery();
  const live = data && data.live;

  const handleDisplayTimes = (): string | undefined | null => {
    // This set the string if the tournament splits a month in half

    const startDate = data && data.start_date && new Date(data.start_date);
    const endDate = data && data.end_date && new Date(data.end_date);

    if (startDate) {
      startDate.setDate(startDate.getDate() + 1);
    }
    if (endDate) {
      endDate.setDate(endDate.getDate() + 1);
    }
    const startMonth = startDate && getMonthString(startDate);
    const endMonth = endDate && getMonthString(endDate);
    if (startDate && endDate && startMonth === endMonth) {
      return `${startMonth} ${startDate.getDate()} - ${endDate.getDate()}`;
    }

    return startDate && endDate && `${startMonth} ${startDate.getDate()} -${endMonth} ${endDate.getDate()}`;
  };

  const hostCourse = data && data.courses && data.courses.find((c) => c.host);
  const tournyId = data && data.remote_id && data?.remote_id.slice(-3);
  const { handleModal } = useModal();
  return (
    <TourneyContainer>
      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <ErrorText>Sorry something went wrong loading the tournament please try again</ErrorText>
      ) : (
        <>
          <div className="sponsor-image">
            <Image
              src={`https://res.cloudinary.com/pgatour-prod/image/upload/v1703694026/tournaments/logos/r${tournyId}.png`}
              layout="fill"
              alt={data?.short_name ? data.short_name : ""}
              unoptimized={true}
            />
          </div>
          <div className="info-container">
            <div className="top-row">
              <div className="tourney-name">{data?.name}</div>
              {live && <LiveBadge round={false} />}
              {showSchedule && (
                <button
                  onClick={() => (overrideModal ? overrideModal() : handleModal(<Schedule />))}
                  className="schedule-button"
                >
                  <CalendarIcon />
                </button>
              )}
            </div>
            <div className="bottom-row">
              <div className="date">
                {data && handleDisplayTimes()} - {hostCourse?.name}
              </div>
              {/* <div className="time">{data?.broadcast?.airtime}</div> */}
              <div className="network-image">
                {/* TODO needs broadcast id for cloudinary */}
                {/* <Image
                  src="https://pga-tour-res.cloudinary.com/image/upload/c_pad,f_auto,h_32,q_auto,w_66/v1/pgatour/logos/networks/mainlogo/77.png"
                  layout="fill"
                  alt={data?.broadcast?.network}
                /> */}
              </div>
            </div>
          </div>
        </>
      )}
    </TourneyContainer>
  );
};

export default TournamentDisplay;
