import styled from "styled-components";
import { palette, pxToRem } from "../styles/styleUtils";

const StyledLive = styled.div`
  display: flex;
  background-color: ${palette.liveRed};
  font-size: ${pxToRem(11)};
  font-weight: bold;
  color: ${palette.white};
  text-transform: uppercase;
  height: 16px;
  width: 36px;
  justify-content: center;
  align-items: center;
  line-height: 1;
  &.round {
    border-radius: 50%;
    height: 26px;
    width: 26px;
  }
`;

interface IProps {
  round: boolean;
  className?: string;
}
const LiveBadge = ({ round, className }: IProps) => {
  return <StyledLive className={`${className} ${round ? "round" : "rectangle"}`}>Live</StyledLive>;
};

export default LiveBadge;
