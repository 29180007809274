import { useRef } from "react";
import { useEffect } from "react";
import { useGameQuery, useTournamentsQuery } from "../../hooks/Queries/useGame";
import { useModal } from "../../hooks/useModal";
import { useRoster } from "../../hooks/useRoster";
import ScheduleSlot from "./ScheduleSlot";
import { ScheduleList, ScheduleWrapper } from "./styles/TournamentDisplay.styled";

let lastSegment: number | null = 0;

interface Props {
  handleClose?: () => void;
}

const Schedule = ({ handleClose }: Props) => {
  const { data: tournamentsData, isLoading, isError } = useTournamentsQuery();
  const { data: gameData } = useGameQuery();
  const { setSelectedTournamentId } = useRoster();
  const { handleModal } = useModal();
  const currentRef = useRef<any>();
  const listRef = useRef<any>();

  useEffect(() => {
    gameData?.current_tournament;
    if (tournamentsData?.tournaments && gameData?.current_tournament) {
      if (currentRef.current && listRef.current) {
        currentRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [tournamentsData, isLoading]);

  const handleTournamentClick = (tournamentId: number | null) => {
    if (tournamentId) {
      setSelectedTournamentId(tournamentId);
      handleClose ? handleClose() : handleModal();
    }
  };

  return (
    <ScheduleWrapper>
      <div className="schedule__title">Schedule</div>
      {isLoading && <div className="tournament-loading">Loading...</div>}
      {isError && <div className="tournament-error">Could not load tournaments schedule. Please try again.</div>}
      {!isLoading && !isError && (
        <>
          <ScheduleList ref={listRef}>
            <>
              {tournamentsData?.tournaments?.map((tournament) => {
                const firstInSegment =
                  lastSegment !== tournament?.segment_id && tournament.segment_id !== null ? true : false;
                lastSegment = tournament?.segment_id;
                const segmentNumber =
                  tournamentsData?.segments?.find((segment) => segment.segment_id === tournament?.segment_id)
                    ?.sequence || 1;
                return (
                  <div
                    ref={gameData?.current_tournament?.tournament_id === tournament.tournament_id ? currentRef : null}
                    key={tournament.tournament_id}
                    className={firstInSegment ? "first-in-segment" : ""}
                  >
                    {firstInSegment && (
                      <div className="segment-tag">
                        {segmentNumber === 1 ? `FedExCup Fall` : `Segment `}
                        {segmentNumber !== 1 && segmentNumber - 1}
                      </div>
                    )}
                    <ScheduleSlot tournament={tournament} onClick={handleTournamentClick} />
                  </div>
                );
              })}
            </>
          </ScheduleList>
        </>
      )}
    </ScheduleWrapper>
  );
};

export default Schedule;
