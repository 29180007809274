import { useQuery } from "react-query";
import { QUERIES } from "../../constants/Queries";
import { API_URL } from "../../settings";
import { IScorecardResponse } from "../../types/interfaces/RosterTypes";
import { useGameQuery, useTournamentQuery } from "./useGame";
import { useRoster } from "../useRoster";

export const fetchScoreCard = (playerId, tournamentId): Promise<IScorecardResponse> => {
  const url = `
  ${API_URL}/static-v1/pgaroster/players/${playerId}/${tournamentId}/scorecard.json`;
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (response?.errors) {
        // TODO better error handling
        reject(new Error(response));
      } else {
        reject();
      }
    }),
  );
};

// React-query hooks

export const useScorecardQuery = () => {
  const { data } = useGameQuery();
  const { data: tdata } = useTournamentQuery();
  const { selectedPlayerId, selectedTournamentId } = useRoster();
  const tournamentId =
    selectedTournamentId && selectedTournamentId !== data?.current_tournament?.tournament_id
      ? selectedTournamentId
      : data?.current_tournament?.tournament_id;

  return useQuery(
    [QUERIES.SCORECARD, selectedPlayerId, tournamentId],
    () => fetchScoreCard(selectedPlayerId, tournamentId),
    {
      staleTime: 1000 * 60 * 2,
      enabled: !!tournamentId && !!selectedPlayerId && selectedPlayerId !== -1,
      refetchInterval: tdata?.live ? 1000 * 30 : false,
    },
  );
};

export const useSpecificScorecardQuery = (playerId: number) => {
  const { data } = useGameQuery();
  const { data: tdata } = useTournamentQuery();
  const { selectedTournamentId } = useRoster();

  const tournamentId =
    selectedTournamentId && selectedTournamentId !== data?.current_tournament?.tournament_id
      ? selectedTournamentId
      : data?.current_tournament?.tournament_id;

  return useQuery([QUERIES.SCORECARD, playerId, tournamentId], () => fetchScoreCard(playerId, tournamentId), {
    staleTime: 1000 * 60 * 2,
    enabled: !!tournamentId && !!playerId && playerId !== -1,
    refetchInterval: tdata?.live ? 1000 * 30 : false,
  });
};
