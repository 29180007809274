import { IScorecardRound, IScoreCardStats } from "../types/interfaces/RosterTypes";
import { IHole } from "../types/interfaces/TournamentTypes";

export const getFantasyPoints = (prs: number) => {
  if (prs <= -3) {
    return 8;
  }
  if (prs === -2) {
    return 5;
  }
  if (prs === -1) {
    return 2;
  }
  if (prs === 0) {
    return 1;
  }
  if (prs === 1) {
    return -1;
  }
  return -3;
};

export const getScoreForHole = (
  holeNumber: number,
  type: "score" | "prs",
  roundScores?: IScorecardRound | null,
): number | "-" => {
  if (roundScores) {
    const score = roundScores[`hole_${holeNumber}_${type}`];
    if (type === "score") {
      return score || score === 0 ? score : "-";
    }
    if (type === "prs") {
      return score || score === 0 ? getFantasyPoints(score) : "-";
    }
  }
  return "-";
};

export const getScoreClassName = (
  holeNumber: number,
  roundScores?: IScorecardRound | null,
  parOnNull = false,
): string => {
  if (roundScores) {
    const prs = roundScores[`hole_${holeNumber}_prs`];
    if (prs === -1) {
      return "birdie";
    }
    if (prs <= -2) {
      return "eagle";
    }
    if (prs === 1) {
      return "bogey";
    }
    if (prs >= 2) {
      return "double-bogey";
    }
    if (prs === null && parOnNull) {
      return "";
    }
  }
  return "par";
};

export const getTotalPar = (holes: IHole[] | undefined): number => {
  if (holes && holes.length > 0) {
    return holes
      .map((hole) => {
        if (typeof hole.par === "number") {
          return hole.par;
        }
        return 0;
      })
      .reduce((a, b) => {
        if (a && b) {
          return a + b;
        }
        return 0;
      });
  }
  return 0;
};

export const getScoreCardStats = (roundScores?: IScorecardRound | null, holes?: IHole[]): IScoreCardStats => {
  const stats = {
    pars: 0,
    birdies: 0,
    eagles: 0,
    bogeys: 0,
    doubleBogeys: 0,
    pars_points: 0,
    birdies_points: 0,
    eagles_points: 0,
    bogeys_points: 0,
    double_bogeys_points: 0,
  };
  holes?.map((hole) => {
    const cls = getScoreClassName(hole.hole_number, roundScores, true);
    const pts = getScoreForHole(hole.hole_number, "prs", roundScores);

    if (cls.includes("par")) {
      stats.pars++;
      if (typeof pts === "number") {
        stats.pars_points += pts;
      }
    } else if (cls.includes("birdie")) {
      stats.birdies++;
      if (typeof pts === "number") {
        stats.birdies_points += pts;
      }
    } else if (cls.includes("eagle")) {
      stats.eagles++;
      if (typeof pts === "number") {
        stats.eagles_points += pts;
      }
    } else if (cls.includes("bogey")) {
      if (cls.includes("double")) {
        stats.doubleBogeys++;
        if (typeof pts === "number") {
          stats.double_bogeys_points += pts;
        }
      } else {
        stats.bogeys++;
        if (typeof pts === "number") {
          stats.bogeys_points += pts;
        }
      }
    }
  });
  return stats;
};

export const getRoundPointsTotal = (roundScores?: IScorecardRound | null, holes?: IHole[]) => {
  if (holes && roundScores) {
    const scoresArr = holes
      .map((hole) => getScoreForHole(hole.hole_number, "prs", roundScores))
      .filter((score) => typeof score === "number");
    // console.log(
    //   scoresArr,
    //   scoresArr.any((a) => typeof a === "number"),
    // );
    if (scoresArr.length !== 0) {
      const roundScore = scoresArr.reduce((a: number, b: number) => {
        return a + b;
      });
      return roundScore;
    }
  }
  return "-";
};
