import styled from "styled-components";
import { breakpoints } from "../../../hooks/useBreakPoints";
import { palette, pxToRem } from "../../../styles/styleUtils";

export const TourneyContainer = styled.div`
  display: flex;
  min-height: 114px;
  @media screen and (max-width: 1600px) and (max-height: 1000px) {
    min-height: 90px;
  }
  .schedule-button {
    width: 60px;
    height: 60px;
    position: relative;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    circle {
      display: none !important;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    min-height: 48px;
    align-items: center;
    margin-bottom: 30px;
    margin-left: 15px;
  }
  .sponsor-image {
    position: relative;
    display: flex;
    height: 90px;
    width: 96px;
    margin-right: 19px;
    justify-content: center;
    align-items: center;
    @media (max-width: ${breakpoints.mobile}) {
      height: 41px;
      width: 39px;
    }
    img {
      object-fit: contain;
    }
    @media (max-width: ${breakpoints.rosterMobile}) {
      height: 39px;
      width: 41px;
    }
  }
  .placeholder-sponsor {
    display: flex;
    height: 90px;
    width: 96px;
    background-color: ${palette.backgroundGrey};
    justify-content: center;
    align-items: center;
    color: white;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    .top-row {
      display: flex;
      align-items: center;
      .tourney-name {
        font-size: ${pxToRem(34)};
        margin-right: 15px;
        @media (max-width: ${breakpoints.mobile}) {
          font-size: ${pxToRem(16)};
        }
      }
      svg.down-arrow {
        transform: rotate(-90deg);
        height: 14px;
        width: 14px;
        background-color: #b9b9b9;
        padding: 1px;
        margin-left: 19px;
        path {
          fill: ${palette.white};
        }
      }
    }
    .bottom-row {
      display: flex;
      align-items: center;
      @media (max-width: ${breakpoints.rosterMobile}) {
        font-size: ${pxToRem(14)};
      }
      .date {
        margin-right: 35px;
        @media (max-width: ${breakpoints.rosterMobile}) {
          text-align: left;
        }
      }
      .time {
        color: ${palette.blue};
      }
      .network-image {
        position: relative;
        display: flex;
        height: 16px;
        width: 78px;
        img {
          object-fit: contain;
        }
      }
      @media (max-width: ${breakpoints.mobile}) {
        display: none;
      }
    }
  }
`;

export const RoundsContainer = styled.div`
  display: flex;
`;

export const RoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  .fedex-logo {
    position: relative;
    object-fit: contain;
    padding-bottom: 34%;
    img {
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }

    @media (max-width: 594px) {
      margin-top: 3px;
    }
  }
  &:not(:first-of-type) {
    margin-left: 22px;
    @media (max-width: 594px) {
      margin-left: 7px;
    }
  }
  text-align: center;
  text-transform: uppercase;
  &.fedex-score {
    text-transform: none;
  }
  .round-live {
    position: absolute;
    bottom: 0;
    right: -5px;
  }
`;

export const RoundScore = styled.button`
  display: flex;
  margin-bottom: 13px;
  border: 2px solid;
  border-radius: 50%;
  height: 77px;
  width: 77px;
  align-items: center;
  justify-content: center;
  font-size: ${pxToRem(26)};
  color: ${palette.textGrey};
  border-color: ${palette.borderGrey};
  font-weight: bold;
  cursor: pointer;
  transition: border-color 0.2s ease;
  position: relative;
  &.active {
    color: ${palette.blue};
    border-color: ${palette.blue};
  }
  &:hover {
    border-color: ${palette.blue};
  }
  &:active {
    // Buttons when clicked set overflow hidden, this hid live badges
    overflow: initial;
  }
  &.fedex-score {
    text-transform: none !important;
  }
  &.fedex-score,
  &.total-score {
    color: ${palette.blue};
    border-color: ${palette.borderGrey};
    cursor: default;
  }

  @media (max-width: 594px) {
    height: 52px;
    width: 52px;
    font-size: ${pxToRem(24)};
  }
`;

export const ScheduleWrapper = styled.div`
  padding: 30px;
  position: relative;
  z-index: 2;
  width: 100%;
  min-width: 400px;
  @media (max-width: ${breakpoints.mobile}) {
    min-width: initial;
  }
  .schedule {
    &__title {
      text-transform: uppercase;
      font-size: ${pxToRem(26)};
    }
  }
  .show-more--button {
    padding-top: 10px;
    button {
      font-size: ${pxToRem(18)};
      color: ${palette.blue};
    }
  }
`;

export const ScheduleList = styled.div`
  overflow-y: auto;
  @media (max-width: ${breakpoints.mobile}) {
    max-height: 85vh;
  }

  .first-in-segment {
    margin-top: 10px;
    border-top: 2px solid ${palette.borderGrey};
    position: relative;
    .segment-tag {
      background: #fff;
      color: ${palette.blue};
      width: fit-content;
      padding: 10px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 700;
    }
  }
`;

export const ScheduleListItem = styled.div`
  height: 100px;
  width: 100%;
  position: relative;
  margin-top: 10px;
  .tourny-button {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .edit-button {
    position: relative;
    z-index: 2;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #d6d6d6;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  .date {
    width: 190px;
    text-align: left;
    @media (max-width: ${breakpoints.mobile}) {
      width: 70px;
    }
  }
  .sponsor-image {
    position: relative;
    display: flex;
    height: 90px;
    width: 96px;
    margin: 0 20px;
    justify-content: center;
    align-items: center;

    & > div {
      display: block;
      margin: 0 auto;
      left: 50% !important;
      width: 100%;
      transform: translateX(-50%);
    }
    @media (max-width: ${breakpoints.mobile}) {
      height: 41px;
      width: 39px;
    }
    img {
      object-fit: contain;
    }
    @media (max-width: ${breakpoints.rosterMobile}) {
      height: 39px;
      width: 41px;
    }
  }
  .name {
    width: 190px;
    text-align: center;
    button {
      margin: 0 auto;
    }
    @media (max-width: ${breakpoints.mobile}) {
      width: 100px;
    }
  }
`;
