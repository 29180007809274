import Image from "next/image";
import { useRouter } from "next/router";
import { useGameQuery } from "../../hooks/Queries/useGame";
import { useRoster } from "../../hooks/useRoster";
import { Button } from "../../styles/buttons.styled";
import { ITournament } from "../../types/interfaces/TournamentTypes";
import { ScheduleListItem } from "./styles/TournamentDisplay.styled";

interface Props {
  tournament: ITournament;
  onClick: (tournamentId: number | null) => void;
}

const ScheduleSlot = ({ tournament, onClick }: Props) => {
  const { setSelectedTournamentId } = useRoster();
  const { data: gameData } = useGameQuery();
  const router = useRouter();
  const getMonthString = (date: Date): string => {
    return date.toLocaleString("default", { month: "short" });
  };
  const handleDisplayTimes = (): string | undefined | null => {
    // This set the string if the tournament splits a month in half

    const startDate = tournament && tournament.start_date && new Date(tournament.start_date);
    const endDate = tournament && tournament.end_date && new Date(tournament.end_date);

    if (startDate) {
      startDate.setDate(startDate.getDate() + 1);
    }
    if (endDate) {
      endDate.setDate(endDate.getDate() + 1);
    }
    const startMonth = startDate && getMonthString(startDate);
    const endMonth = endDate && getMonthString(endDate);
    if (startDate && endDate && startMonth === endMonth) {
      return `${startMonth} ${startDate.getDate()} - ${endDate.getDate()}`;
    }

    return startDate && endDate && `${startMonth} ${startDate.getDate()} -${endMonth} ${endDate.getDate()}`;
  };

  const isPast = (): boolean => {
    const endDate = tournament && tournament.end_date && new Date(tournament.end_date);
    if (endDate) {
      return endDate.getTime() < Date.now();
    }
    return false;
  };
  const isOpen = gameData?.current_tournament?.tournament_id === tournament.tournament_id;
  const tournyId = tournament && tournament.remote_id && tournament?.remote_id.slice(-3);

  return (
    <ScheduleListItem className={`${isPast() ? "past" : ""}`} onClick={() => onClick(tournament.tournament_id)}>
      <button
        className={`tourny-button ${isPast() || isOpen || tournament?.pickable ? "button-present" : ""}`}
        onClick={() => onClick(tournament.tournament_id)}
      ></button>
      <div className="date">{handleDisplayTimes()}</div>
      <div className="sponsor-image">
        <Image
          src={`https://res.cloudinary.com/pgatour-prod/image/upload/v1703694026/tournaments/logos/r${tournyId}.png`}
          layout="fill"
          alt={tournament?.short_name ? tournament.short_name : ""}
          unoptimized={true}
        />
      </div>
      <div className="name">
        {tournament.name}
        {(isOpen || isPast() || tournament?.pickable) && (
          <Button
            onClick={() => {
              setSelectedTournamentId(tournament?.tournament_id);
              isPast() &&
                !router.pathname.includes("/leaderboard") &&
                !router.pathname.includes("/leagues") &&
                router.push("/roster");
              isOpen && tournament?.pickable && router.push("roster");
            }}
            className="edit-button"
          >
            {isPast() ? "View Results" : isOpen ? "Edit Roster" : tournament?.pickable === true ? "Make Picks" : ""}
          </Button>
        )}
      </div>
    </ScheduleListItem>
  );
};

export default ScheduleSlot;
